@charset 'utf-8';

/* nagłówek witryny */
#site-header {
    z-index: 12;
/*    position: -webkit-sticky;  Safari 
    position: sticky;*/
    top: 0;
    height: auto;
    background-color: color(header);
    /*  
      background: gradient(ms);  ms ( IE 10.0+ ) 
      background: gradient(webkit);  WebKit (Chrome 10.0+, safari 5.1+ )
      background: gradient(moz);  Moz ( Moz 3.6+ )
      background: gradient(opera);  Opera ( opera 11.6+ )
      background: gradient(w3c);  W3C Markup 
      box-sizing: border-box;
      border-bottom: 1px solid color(f-blue);
    */
    &.container-fluid {
		margin: 0;
        padding: 5px 0 5px;
    }
    
    @include respond-to(small){
        position: relative;
    }
	
	>.sign {
		display: none;
	}

    .navbar {
        height: auto;
        padding: 0 16px;
        /*text-align: center;*/
        @include respond-to(medium){
            justify-content: left;
        }

        /* logo i tytuł */
        .navbar-brand {
            display: block;
            margin: 0;
            padding: 5px 0;
            height: auto;

            &:hover {
                color: color(none);
            }      

            img {
                display: block;
                margin: 0;
                max-width: 60vw;
                max-height: 40px;
            }

            @include respond-to(small){
                padding: 5px 0 10px;

                img {
                    height: 50px;
					max-height: 50px;
                }
            }

            @include respond-to(medium){

                img {
                    height: 40px;
					max-height: 40px;
                }
            }

            @include respond-to(large) {

                img {
                    height: 60px;
					max-height: 60px;
                }
            }
        }
        
        .navbar-toggler {
            border-color: color(f-blue);
            
            span.navbar-toggler-icon {
                color: color(f-blue);
            }
        }

        /* od tego momentu nawigacja główna jest widoczna */
        @include respond-to(medium) {
            justify-content: space-between;
        }

        /* nawigacja główna*/
        .navbar-collapse {
            margin: 0;

            &.collapse.show {

                @include respond-to(medium){
                    align-items: justify;
                }
                .navbar-nav {

                    .nav-item {

                        display: block;
                        width: 100%;

                        @include respond-to(medium){
                            display: inline;
                        }

                        .nav-link {
                            text-align: right;
                            width: 100%;

                            @include respond-to(large){
                                text-align: center;
                            }
                        }

                        .dropdown-menu {
                            padding: 0;
                            margin: 0;
                            color:white;
                            background-color: tint(f-green, 10);
                            border: none;

                            @include respond-to(medium){
                                padding: 0.5rem 0;
                                margin: 0.125rem 0;
                                color: color(f-green);
                                background-color: color(f-none);
                                border: none;
                            }

                            .dropdown-item {
                                text-align: right;
                                font: font(menu-default-link);
                                color:white;
                                background-color: tint(f-green, 20);
                                border: none;
                                border-radius: 0;
                                cursor: pointer;

                                &:hover {
                                    background-color: shade(f-green, 10);
                                }

                                @include respond-to(medium){
                                    text-align: center;
                                    color: color(f-green);
                                    background-color: white;
                                    border: 1px solid color(f-green);
                                    border-radius: 5px;

                                }
                            }

                        }
                    }


                }
            }

            @include respond-to(medium) {
                padding: 0;
            }

            padding: 0;
            border: 0;
            box-sizing: border-box;
            background-color: color(f-green);

            @include respond-to(medium){
                background-color: color(f-none);
            }

            > .navbar-nav {
                height: auto;
                box-sizing: border-box;

                > .nav-item {

                    @include respond-to(medium){
                        margin-left: 5px;
                    }
                    box-sizing: border-box;
                    border: none;

                    > .nav-link {
                        box-sizing: border-box;
                        display: block;
                        color: white;
                        text-align: right;
                        cursor: pointer;
                        font: font(menu-default-link);
                        border: none;
                        padding: 0 15px;
						
                        @include respond-to(medium){
                            border-radius: 5px;
                            padding: 0 10px;
                            text-align: center;
                            color: color(f-blue);
                            transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
                            border: 1px solid color(f-blue);
                            background-color: color(none);
                        }

                        &:hover {
                            border: none;
                            background-color: shade(f-green, 15);
                            color: white;
                            @include respond-to(medium){
                                border: 1px solid color(f-green);
                                background-color: color(f-green);
                                color: white;
                            }
                        }

                        @include respond-to(medium){
                            display: inline-block;
                        }

                        @include respond-to(large){
                            padding: 0 15px;

                            &#dropdownUser {
                                padding: 0 4px;
                            }
                        }

                        &.nav-link-home {

                            @include respond-to(large){
                                padding: 0 11px;
                            }
                        }
						
						&.dropdown-toggle {
							padding-right: 4px;
						}

                        > span.big {

                            display: inline;

                            @include respond-to(medium) {
                                display: none;
                            }

                            @include respond-to(big) {
                                display: inline;
                            }
                        }

                        > span.sm {

                            display: inline;

                            @include respond-to(medium) {
                                display: none;
                            }
                        }
                    }

                    // menu główne aktywne
                    &.active {

                        .nav-link {
                            box-sizing: border-box;
                            border: none;
                            background-color: shade(f-green, 10);

                            @include respond-to(medium){
                                transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
                                border: 1px solid color(f-green);
                                background-color: color(none);
                                color: color(f-green);
                            }
                            cursor: default;

                            &:hover {
                                background-color: shade(f-green, 10);
                                color: white;
                                cursor: default;
								
								&.dropdown-toggle{
									cursor: pointer;
								}

                                @include respond-to(medium){
                                    background-color: color(none);
                                    color: color(f-green);
                                }

                            }

                            &.nav-link-home,
                            &.nav-link-home-ico {
                                background-color: color(f-green);

                                @include respond-to(medium){
                                    background-color: white;
                                }

                                &:hover {
                                    background-color: shade(f-green, 10);
                                    color: white;
                                    cursor: pointer;

                                    @include respond-to(medium){
                                        border: 1px solid color(f-green);
                                        background-color: color(f-green);
                                        color: white;
                                    }
                                }
                            }

                            &.ready {
                                background-color: shade(f-green, 10);
                                color: white;
                                cursor: pointer;
                                border: none;

                                @include respond-to(medium){
                                    border: 1px solid color(f-green);
                                    background-color: color(f-green);
                                    color: white;
                                }

                                &:hover {
                                    cursor: pointer;
                                }
                            }
                        }
						
						&.show {
							
							.dropdown-menu {
								
								.dropdown-item {
									
									&.active {
										background-color: color(f-green);
										color: white;
										border-color: color(f-green);
										cursor: default;
										
										&:hover {
											color: white;
										}
									}
								}
							}
						}
                    }

                    &.show{

                        .dropdown-menu {

                            @include respond-to(medium){
                                padding: 0;
                                margin: 0;
                                color: color(f-blue);
                                background-color: color(f-none);
                                border: none;
                            }

                            .dropdown-item {
                                font: font(menu-default-link);
                                cursor: pointer;

                                @include respond-to(medium){
                                    text-align: center;
                                    color: color(f-blue);
                                    background-color: white;
                                    border: 1px solid color(f-blue);
                                    border-radius: 5px;

                                    &:hover {
                                        background-color: color(f-green);
                                        border: 1px solid color(f-green);
                                        color: white;
                                    }

                                }
                            }

                        }
                    }
                }
            }
        }
    }
}
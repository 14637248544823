@charset 'utf-8';

main {
	min-height: calc( 100vh - 448px );

	&.main-cid-1 {

		.page-header {
			border-top-color: rgb(37, 82, 117);
			border-bottom-color: rgb(37, 82, 117);
		}
	}

	.btn-primary {
		background-color: color(f-blue);
		border-color: color(f-blue);
	}

	a {
		color: color(f-blue);
		.active {
			color: color(f-green);
		}
	}
	
	/******* label *******/
	.collection-label {
		display: inline-block;
		font-family: Lato;
		padding: 0 12px;
		font-size: 15px;
		font-weight: 700;
		line-height: 2.2;
		background-color: #cceeff;
		color: #4aa4ea;
		border-radius: 15px;
		
		.emticon {
			display: inline-block;
			font-size: 140%;
			line-height: 1;
			vertical-align: middle;
			margin-right: 5px;
			margin-left: -3px;
			font-weight: bold;
		}
	}
	
	.block-group {
		
		.block-box {
			
			.block-content {
				
				.default-photo {
					width: 100%;
					max-width: 400px;
					text-align: center;
					clear: both;
					
					@include respond-to(medium) {
						float: left;
						clear: left;
						margin-right: 20px;
						margin-bottom: 15px;
					}
					
					input {
						text-align: left;
					}
					label {
						text-align: left;
						
						&.custom-file-label {
							font-size: .95em;
						}
					}
					.prepend-file {
						font-size: .95em;
						
						.input-group-text {
							font-size: .95em;
						}
					}
				}
			}
		}
	}
}
@charset 'utf-8';

/// the ~ allows you to reference things in node_modules
@import 
  '~@fortawesome/fontawesome-free/scss/fontawesome',
  '~@fortawesome/fontawesome-free/scss/regular',
  '~@fortawesome/fontawesome-free/scss/solid',
  '~@fortawesome/fontawesome-free/scss/brands',
  '~bootstrap/scss/bootstrap';

@import 
  'base/girds',     // xs:0, sm:576px (small), md:768px (medium), lg:992px (large), xl:1200px (big) (@include respond-to(big){})
  'base/colors',    // color(<key>) | tint(<key>, <percent>) [rozjaśnienie] | shade(<key>, <percent>) [przyciemnienie]
  'base/gradients', // gradient(<key>)
  'base/animations', // [keyframes]<key>
  'base/shadows',   // shadow(<key>)
  'base/general';

@import
  'fonts/fonts';    // font(<key>)
  
@import
  'layout/cookie',
  'layout/body',
  'layout/topnav',
  'layout/header',
  'layout/admin',
  'layout/main',
  'layout/pageheader',
  'layout/content',
  'layout/footer';
  
@import
  'modules/message',
  'modules/slider';

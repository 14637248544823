@charset 'utf-8';

#cookie-info {
	position: relative;
    background-color: shade(f-blue,50);
    color: tint(f-blue, 55);
    padding: 8px;
    font-size: 10px;
    line-height: 1.1;
    z-index: 1050;
    display: none;
    
    @include respond-to(small) {
        padding-top: 4px;
        padding-bottom: 4px;
        font-size: 10px;
        line-height: 1.25;
    }
    @include respond-to(medium) {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 10px;
        line-height: 1.4;
        
    }
    
    @include respond-to(large) {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 13px;
        line-height: 22.5px;
    }

    p {
        margin: 0;
        padding: 0;
        display: inline-block;
    }
    
    .cookie-btn {
        width: auto;
        cursor: pointer;
        color: tint(f-blue, 75);
        font-size: 22px;
        font-weight: bold;
        line-height: 14px;
        padding: 0;
        text-align: center;
        margin: -4px 6px 0 3px;;
        
        &:hover {
            color: tint(f-blue, 99);
        }
    }
}
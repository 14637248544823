@charset 'utf-8';

body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    box-sizing: border-box;
    font: font(base);
    color: color(base);

	
    /*background-color: tint(f-blue, 95);*/
    /*
      background-color: white;
      background: gradient(ms);  //ms ( IE 10.0+ ) 
      background: gradient(webkit);  //WebKit (Chrome 10.0+, safari 5.1+ )
      background: gradient(moz);  //Moz ( Moz 3.6+ )
      background: gradient(opera);  //Opera ( opera 11.6+ )
      background: gradient(w3c);  //W3C Markup 
      background-size: 100% 100%;
    */
    @each $key, $value in $fonts {
        .h-#{$key} {
            font: $value;
        }
    }

    @each $key, $value in $colors {
        .h-#{$key} {
            color: $value;
        }
        .btn-#{$key},
        .message-#{$key} {
            background-color: $value;
            border-color: shade($key, 15);
        }
        .btn-#{$key}:hover,
            .message-#{$key}:hover {
            background-color: shade($key, 15);
        }
        .text-#{$key},
        .link-#{$key} {
            color: $value;
        }
        .text-#{$key}:hover,
		.link-#{$key}:hover {
            color: shade($key, 15);
        }
		.bg-#{$key} {
			background-color: $value;
		}
    }
	
	.note-editor {
		background-color: white;
	}
	
	&#body-cid-1 {
		background-color: rgb(37, 82, 117);
	}
	
	.bg-odd{
		background-color: color(odd);
	}
	.bg-even{
		background-color: color(even);
	}

    a {
        color: color(link);

        &:hover {
            color: color(active);
            text-decoration: none;
        }
    }
	
	.btn:disabled {
		opacity: 0.15;
	}
	
	.cursor-i {
		cursor: help;
	}
	
	.cursor-p {
		cursor: pointer;
	}
	
	.font-normal{
		font-weight: 400;
	}
	
	.btn-dark-red {
		background-color: color(f-red-dark-trans);
		color: white;
		
		&:hover {
			background-color: shade(red-dark,15);
			color: white;
		}
	}
	
    >.width {
        position: fixed;
        width: auto;
        top: 2;
        left: 4;
        padding: 0;
        font-size: 12px;
        line-height: 12px;
        color:  transparent;
    }

    form input.error,
    form select.error,
    form textarea.error{
        border-color: color(f-red);
    }
	form label.error{
		color: color(f-red);
	}
	form input.error[type="checkbox"]{
		background-color: white;
		outline: 2px solid color(f-red);
	}

    .row-developer {
        margin-top: 10px;
        margin-bottom: 10px;

        button {
            color: red;
            font-size: 0.8rem;
            line-height: 0.95rem;
            padding: 0.15rem 0.4rem;
            opacity: .35;

            &:hover {
                color: yellow;
            }
        }

        #collapseDev {
            border: 1px dotted red;
            border-radius: 10px;
            background-color: rgb(252, 253, 255);
            margin: 10px -10px;
            padding: 10px;
        }
    }

    table {

        tr {

            th {
                color: color(title);
                font-family: Lato;
                font-weight: 400;
            }

            td {
                color: color(base);
            }
			
			&.no-wrap {
				td {
					white-space: nowrap;
					
					* {
						white-space: nowrap;
					}
				}
			}
        }
    }

    .modal-dialog {
        background-color: color(none);
        
        &.modal-dialog-centered {
            max-height: calc(100vh - 3.5rem);
        }

        .modal-content{

            .modal-header{
                background-color: shade(body-light, 8);
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                
                .modal-title {
                    font-size: 1.5rem;
                }
            }

            .modal-body {    
                background-color: shade(body-light, 3);
                overflow: auto;

                .accordion {

                    > .card {

                        > .card-header {
                            padding: 0;

                            > h2 {

                                > button.btn-link {
                                    width: 100%;
                                    padding: 1.125rem 2rem;
                                    text-align: left;
                                    background-color: shade(body-light, 40);
                                    color: white;

                                    &[aria-expanded="true"] {
                                        text-decoration: none;
                                        background-color: color(f-blue);
                                        color: white;
                                        border-bottom-left-radius: 0;
                                        border-bottom-right-radius: 0;

                                        &:hover {
                                            cursor: default;
                                        }  
                                    }

                                    &:focus {
                                        text-decoration: none;
                                    }

                                    &:hover {
                                        text-decoration: none;
                                        background-color: color(f-blue);
                                        color: white;
                                    }
                                } 
                            }
                        }

                        > .collapse {
                            background-color: shade(body-light, 1);
                            color: color(dark);

                            .ui-datepicker-append{
                                display: block;
                            }

                            small {
                                display: inline-block;
                                padding: 0 calc( 0.75rem + 1px );
                            }

                            .ico-help {
                                position: absolute;
                                top: -11px;
                                right: -1px;
                                color: color(f-blue);
                                z-index: 10;
                            }

                            .ico-required {
                                position: absolute;
                                top: 10px;
                                left: 5px;
                                color: color(f-red);
                                font-size: 0.5em;
                                z-index: 9;
                            }              
                        }
                    }          
                }
                .modal-footer {
                    padding-bottom: 6px;
                    clear: both;

                    .small {
                        position: relative;
                        margin-left: 0; margin-right: auto;
                        padding: 0 calc( 0.75rem + 1px );
                        font-size: 0.8rem;

                        .ico-required {
                            position: absolute;
                            top: 4px;
                            left: 0;
                            color: color(f-red);
                            font-size: 0.6em;
                        }
                    }
                }
                &:after {
                    content: "\a0";
                    display: block;
                    width: 100%;
                    clear: both;
                    font-size: 0;
                    line-height: 0;
                }
            }
        }
    }
    
    .clear {
        overflow: auto;
        
        &:after {
            content: "\a0";
            display: block;
            width: 100%;
            clear: both;
            font-size: 0;
            line-height: 0;
        }
    }
    
    .f-right {
        float: right;
    }
    .f-left {
        float: left;
    }
    .f-none {
        float: none;
    }
	
	.caret-right-fill {
		display: inline-block;
		width: 16px;
		height: 1em;
		color: inherit;
		background-repeat: none;
		background-image: url('/build/images/caret-right-fill.svg');
		background-position: center center;
	}

	.caret-down-fill {
		display: inline-block;
		width: 16px;
		height: 1em;
		color: inherit;
		background-repeat: none;
		background-image: url('/build/images/caret-down-fill.svg');
		background-position: center center;
	}
	
	.alert.container {
		z-index: 999999;
	}
	
	.btn-caret {
		border: 0;

		.fas {

			&.fa-caret-right {
				color: color(f-red);
			}

			&.fa-caret-down {
				color: color(active);
			}
		}

	}
	
	.data-copy {
		cursor: pointer;
		
		&:hover:after {
			content: ' paste';
			color: color(f-red);
			letter-spacing: 2px;
		}
	}
	
	.data-copy-data {
		cursor: pointer;
		background-color: color(white-light);
		color: color(dark);
		
		&:hover {
			color: shade(dark, 90); 
			background-color: color(white-very-light);
		}
		
		&.active {
			color: black;
			background-color: white;
		}
	}
	
	.modal-gallery {
		position: fixed;
		z-index: 2000;
		padding-top: 10vh;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: auto;
		background-color: rgba(0,0,0,0.8);

		/* The Close Button */
		.close {
			color: white;
			position: absolute;
			top: 10px;
			right: 25px;
			font-size: 35px;
			font-weight: bold;

			&:hover,
				&:focus {
				color: #999;
				text-decoration: none;
				cursor: pointer;
			}
		}

		.modal-content {
			position: relative;
			background-color: rgba(0,0,0,0.3);
			margin: auto;
			padding: 10px 0;
			width: 100%;
			max-width: 800px;

			.slide-image {
				/* Hide the slides by default */
				display: none;
				width: 100%;
				text-align: center;

				.slide-number {
					color: white;
					background-color: rgba(0,0,0,0.55);
					text-align: center;
					font-size: 16px;
					line-height: 25px;
					width: 50px;
					padding: 0;
					position: absolute;
					top: -25px;
					left: -50px;
					border-radius: 5px;
				}

				img {
					width: auto;
					height: auto;
					margin: 0 auto;
					max-width: 100%;
					max-height: calc(90vh - 190px);
					transition: 1s;

					&:hover {
						box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
					}
				}
			}

			/* Next & previous buttons */
			.prev,
			.next {
				cursor: pointer;
				position: absolute;
				top: 50%;
				width: auto;
				padding: 16px;
				margin-top: -50px;
				color: white;
				background-color: rgba(0,0,0,0.15);
				font-weight: bold;
				font-size: 20px;
				transition: 0.6s ease;
				border-radius: 0 3px 3px 0;
				user-select: none;
				-webkit-user-select: none;

				&:hover{
					background-color: rgba(0, 0, 0, 0.8);
				}
			}

			/* Position the "next button" to the right */
			.next {
				right: 0;
				border-radius: 3px 0 0 3px;
			}

			.slide-name-box {
				text-align: center;
				padding: 0;
				color: white;
				font-size: 16px;
				line-height: 40px;

				.slide-name {
					/* Hide the slides by default */
					display: none;
					margin: 0 0 10px;
					padding: 2px;
				}
			}

			.slide-thumb-box {
				font-size: 0; 
				line-height: 0;

				.slide-thumb {
					opacity: 0.6;
					padding: 1px;

					&.active,
					&:hover {
						opacity: 1;
					}
				}
			}

		}
	}	
	
	.open-modal {
		cursor: pointer;
	}
	
	.input-group {
		.input-group-prepend {

			&.error {

				.input-group-text {

					background-color: color(f-red);
					color : white;
					border-color: color(f-red);
				}
			}
		}
		.input-group-append {

			&.error {

				.input-group-text {

					border-color: color(f-red);
				}
			}
		}
		
		&.small {
			line-height: 1.15em;
			padding: 0;
			
			*{
				line-height: 1.15em;
			}
			
			.input-group-prepend {

				.input-group-text {
					padding: 0.25em;
					line-height: 1.15em;
					font-size: 1.15em;
					height: calc(1.5em + 0.2rem + 2px);
				}
			}
			input.form-control {
				padding: 0.25em;
				line-height: 1.15em;
				height: calc(1.5em + 0.2rem + 2px);
				font-size: 1.2em;
			}
			.input-group-append {

				.input-group-text {
					padding: 0.25em;
					line-height: 1.15em;
					font-size: 1.15em;
					height: calc(1.5em + 0.2rem + 2px);
				}
			}
		}
	}
	input {
		
		&.error {
			
			&:focus,
			&:active {
				border-color: color(f-red);
				box-shadow: none;
			}			
		}
		
		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			color: rgb(140,150,200);
		}
		&::-moz-placeholder { /* Firefox 19+ */
			color: rgb(140,150,220);
		}
		&:-ms-input-placeholder { /* IE 10+ */
			color: rgb(140,150,220);
		}
		&:-moz-placeholder { /* Firefox 18- */
			color: rgb(140,150,220);
		}
		&::placeholder{
			color: rgb(140,150,220);
		}
		
		&.required {
			
			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				color: rgb(235,110,110);
			}
			&::-moz-placeholder { /* Firefox 19+ */
				color: rgb(235,110,110);
			}
			&:-ms-input-placeholder { /* IE 10+ */
				color: rgb(235,110,110);
			}
			&:-moz-placeholder { /* Firefox 18- */
				color: rgb(235,110,110);
			}
			&::placeholder{
				color: rgb(235,110,110);
			}			
		}
	}
	
	/******* strzałki w polu numerycznym *******/
	/* Chrome, Safari, Edge, Opera */
	input.form-control::-webkit-outer-spin-button,
	input.form-control::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	/* Firefox, IE, CSS3 */
	input.form-control[type="number"]{
		-moz-appearance: textfield; /* Mozilla */
		-ms-appearance: none; /* Internet Explorer */
		appearance: none; /* CSS3 */

		font-size: 0.9rem;
		/*border-right: none;*/
	}
	
	.clear-fields,
	.clear-search,
	.clear-amounts {
		cursor: pointer;
	}
	
	#searchForm {
		.search-btn {
			cursor: pointer;
			
			&:hover {
				background-color: tint(f-blue, 20);
			}
		}
	}
	
	.panel-heading {
		
		&.note-toolbar {
			background-color: #f5f5f5;
			
			.note-btn {
				border: 1px solid #ccc;
			}
		}
	}
}

@charset 'utf-8';

@import 
'../base/girds',
'../base/colors';

#cart {
	position: relative;
	text-align: center;
	padding:0;
	margin:0;
	font-size: 0;
	line-height: 0;
	z-index: 10;
	
	.container.relative {
		text-align: right;

		.no-cart,
		.no-cart * {
			color: transparent;
			font-size: 0;
			line-height: 0;
			padding:0;
			margin:0;
		}

		.in-cart {
			display: block;
			position: relative;
			width: 40px;
			margin: 10px 0 -10px auto;
			padding: 0;
			font-size: 0;
			line-height: 0;
			text-align: center;

			@include respond-to(small){
				margin: 0 0 -25px auto;
			}
			
			@include respond-to(medium){
				margin: 0 0 -25px auto;
			}
			
			@include respond-to(large){
				margin: -5px 0 -25px auto;
			}

			@include respond-to(big){
				margin: 0 0 -40px auto;
			}

			i {
				padding: 0;
				margin: 0;
				font-size: 40px;
				line-height: 40px;
				color: color(f-red);
			}

			.qty-cart {
				position: absolute;
				width: 39px;
				left: 0;
				top: 0;
				font-size: 13px;
				font-weight: bold;
				line-height: 40px;
				text-align: center;
				color: color(f-white);
				z-index: 5;
			}

			.name-cart {
				position: absolute;
				width: 126px;
				left: -130px;
				top: 6px;
				font-size: 15px;
				line-height: 24px;
				font-weight: 600;
				text-align: center;
				background-color: color(img-bg);
				border: 1px solid color(img-bg);
				color: color(f-red);
				border-radius: 4px;
			}
		}
	}
}
@charset 'utf-8';

main {
  .page-header {
    /*background-color: color(nav-bg);*/
    border-top: 1px solid color(f-blue);
    border-bottom: 1px solid color(f-blue);
    margin: 0 -15px;
    padding: 15px;

    .container-title {

      .h-title-mobile {
        letter-spacing: -0.05vw;
        text-align: center;
        color: color(none);
        background: color(f-blue);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        /*text-shadow: shadow(text-h-mob);*/
        margin-bottom: 0; 
        margin-top: 0;
        font: font(title-page-mobile);
        
        @include respond-to(small){
          font: font(title-page);
        }
      }      
    }
  }
}


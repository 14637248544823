@charset 'utf-8';

/* top menu */

body {

	>.navbar {
		padding-bottom: 0;

		#navbarTopMenu {
			.nav-item {
				margin: 0 20px 0 0;
				white-space: nowrap;
				line-height: 16px;

				&:last-of-type {
					margin-right: 0;
				}

				.nav-link {
					padding: 0;
					margin: 0;
					cursor: pointer;
					line-height: 16px;
					color: color(f-blue);
					border-color: color(f-blue);

					&.active,
					&:hover {
						color: color(f-green);
						border-color: color(f-green);
					}			
				}

				.dropdown-menu {

					.dropdown-item {
						font-size: 0.85rem;
						color: color(f-blue);
						border-bottom-color: color(f-blue);
					}				
				}


				&.locale-chooser {

					.set-lang {
						margin: 0 3px 0 0;
						display: inline-block;
						filter:alpha(opacity=60);
						opacity: 0.6;
						-moz-opacity: 0.6;
						border: 1px solid color(f-blue);

						&.active {
							filter:alpha(opacity=100);
							opacity: 1;
							-moz-opacity: 1;
							border: 1px solid color(f-green);
						}

						&:last-of-type {
							margin: 0;
						}
					}
				}

				&.globe-link {

					.globe {
						display: block;
						width:20px; height: 20px; 
						background-image: url('/build/images/globe30.png'); 
						background-position: center center; background-size: cover;
						background-repeat: no-repeat;

						&:hover {

							animation: hov 10s infinite;
							animation-direction: alternate;

							@keyframes hov {
								0% {
									transform: rotate(0);    
								}
								50% {
									transform: rotate(360deg);
								}
								100% {
									transform: rotate(-360deg);
								}
							}
						}
					}
				}
			}
		}
	}
	
	&#adminPage {
		>.navbar {
			height: 40px;
			padding-bottom: 0;
			background-color: color(dark);
			padding: 0;
			font-size: 0.95rem;
			font: font(menu-link);

			.navbar-toggler {
				color: white;
				background-color: color(f-blue);
				padding: 0.10rem 0.40rem;
				margin-top: 8px;

				@include respond-to(small) {
					margin-top: 15px;
				}
			}
			
			#navbarTop {
				
				.navbar-nav {
										
					.nav-item {
						margin: 0;
						text-align: left;
						
						@include respond-to(big) {
							text-align: center;
							width: auto;
						}

						a {
							white-space: nowrap;
							width: 100%;
							padding: 0 20px;
							cursor: pointer;
							color: color(cancel);
							border: 1px solid shade(dark, 50);
							background-color: shade(dark, 30);

							&:hover{
								background-color: shade(dark, 40);
								
								.d-hover-inline-block {
									display: inline-block;
								}

								.d-hover-none {
									display: none;
								}
								
								&.active {
									background-color: shade(dark, 40);
									color: tint(f-green, 10);								
								}
							}
							
							
						}
						
						&.active {
							
							>a {
								background-color: shade(dark, 40);
								color: tint(f-green, 10);
								cursor: default;
								
								&.dropdown-toggle {
									cursor: pointer;
									color: tint(f-green, 30);
								}
							}
						}
						
						.nav-link {
							margin: 0 0 1px;
							line-height: 37px;
							border-radius: 0;
						}

						.dropdown-menu {
							background-color: transparent;
							border: none;
							padding: 0;

							&.right-0 {
								left: auto;
								right: 0;
							}
							
							.dropdown-item {
								line-height: 1.5rem;
								text-align: left;
								display: block;
								border-radius: 5px;
								padding: 0.5rem 20px;
								margin: 0 0 2px;
								
								&.active {
									background-color: shade(dark, 40);
									color: tint(f-green, 10);
									cursor: default;
								}
							}

							.dropdown-divider{
								border-top-color: tint(f-blue, 80);
								margin-bottom: 2px;
								margin-top: 2px;
							}
						}
					}
				}
			}
		}
		
		>.admin-messages {
			display: flex !important;
			flex-flow: column wrap;
			justify-content: flex-start;
			align-items: center;
			z-index: 1040;
			padding-top: 0;
			padding-bottom: 0;
			margin-top: 0;
			margin-bottom: 0;
			background-color: color( white-light);
			
			@include respond-to(big){
				top: 44px !important;
			}
			
			> .alert {
				display: flex !important;
				flex-direction: row;
				flex-basis: auto;
				flex-grow: 1;
			}
		}
	}
}

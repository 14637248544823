@charset 'utf-8';

// Przykład, jak pokolorować kolejne elementy (tła) w danej klasie (tylko kolor)
.slide {
  $numItems: 10;
  $eachDeg: 100deg / $numItems;
  $color: color(active);
  @for $idx from 1 through $numItems {
    &:nth-child(#{$idx}) {
      background-color: adjust-hue($color, $eachDeg * $idx);
    }
  }
}
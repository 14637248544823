@charset 'utf-8';

main {
    /*min-height: calc( 100vh - 150px );*/
    z-index: 2;

    .container-content {
        padding-top: 80px;
        padding-bottom: 30px;
		
		@include respond-to(small){
	        padding-top: 60px;
		}
		
		@include respond-to(medium){
			margin-top: 15px; 
		}
		
		@include respond-to(large){
			margin-top: 0;
		}		
	  
	    &.container-cid-1 {
		  padding-top: 70px;
		  padding-bottom: 0;
		  margin-top: -20px;
		}
		
		&.admin-content {
			padding-top: 10px;
		}
    }

    .admin-action.btn,
	.page-action.btn,
	.block-action.btn {
      color: white;
      background-color: color(f-red);
      border: none;
      padding: 0;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      z-index: 100;

      &::before {
        display: block;
        width: 30px;
        height: 30px;
        line-height: 30px;
      }

    &.fa-save {
            background-color: color(f-green);
        }

        &.fa-edit {
            background-color: color(f-blue);
        }

        &.fa-window-close {
            background-color: color(dark);
        }

        &.fa-trash-alt {
            background-color: color(f-red);
        }
        
        &.fa-arrow-alt-circle-up{
            background-color: color(f-gold);
        }
    }

    .dn {
        display: none;
    }

    .db {
        display: block;
    }

    .input-group,
    .custom-control {
        padding-top: 8px;
        padding-bottom: 8px;

        &.custom-radio,
        &.custom-checkbox {

            .custom-control-label {

                margin-left: 22px;
                cursor: pointer;

                &:before,
                &:after {
                    left: -22px;
                }
            }

        }
    }
    
    .row-developer {
        text-align: left;
    }
    
    .form-group label span {
        white-space: nowrap;
    }
    
    .f-blue {
        color: color(f-blue);
    }
    
    .f-blue-background {
        background-color: color(f-blue)l
    }

}
@charset 'utf-8';

.container-admin {
    padding: 0 15px;
    margin: 0;
    background-color: color(f-blue);
    z-index: 999;

    .admin-title {
        display: block;
        font: font(title-6);
        color: white;
        line-height: 36px;
        width: auto;
        margin-right: auto;
        padding: 0;
    }

    .admin-actions {
        margin-left: auto;
        
        > a,
        > .button,
        > button,
        > .btn,
        > btn,
        > div.admin-icon {
            border: none;
            width: auto;
            float: right;
            margin: 3px 2px;
            padding: 0 5px;
            border-radius: 5px;
            display: block;
            text-align: center;
            color: color(f-blue);
            background-color: tint(nav-bg, 90);
            font: font(admin-link);
            line-height: 30px;
            min-width: 36px;

            &.active {
                background-color: color(f-blue);
                color: white;
            }

            &.disabled {
                color: color(inactive);
            }

            &:hover {
                color: tint(nav-bg, 100) !important;
                background-color: color(f-blue) !important;
                border-color: color(f-blue) !important;
                text-decoration: none;

                &.disabled {
                    color: tint(inactive,70) !important;
                    background-color: tint(inactive, 30) !important;          
                }

                &.active {
                    background-color: tint(nav-bg, 100);
                    color: color(f-blue);
                }
            }

        }
    }	
}

#adminPage {
	
	td {
		
		&.by-user {
			color: color(f-green-dark);
		}
		
		&.by-admin {
			color: color(f-blue-dark);
		}
		
		&.td-admin-buttons {
			line-height: 0;
			font-size: 0;

			.btn-row-search-border {
				font-size: 15px;
				line-height: 15px;
				padding: 2px;
				line-height: 1;

				i {
					width: 15px;
					text-align: center;
				}
			}
		}
	}
}

/*
display medium and larger (tablet and computer)
*/
.min-sm-inline,
.min-sm-inline-block,
.min-sm-block,
.min-md-inline,
.min-md-inline-block,
.min-md-block,
.min-ld-inline,
.min-ld-inline-block,
.min-ld-block,
.min-xl-inline,
.min-xl-inline-block,
.min-xl-block {
    display: none;  
}
@include respond-to(small) {
    .min-sm-block {
        display: block;
    }
    .min-sm-inline-block {
        display: inline-block;
    }
    .min-sm-inline {
        display: inline;
    }
}
@include respond-to(medium) {
    .min-md-block {
        display: block;
    }
    .min-md-inline-block {
        display: inline-block;
    }
    .min-md-inline {
        display: inline;
    }
}
@include respond-to(large) {
    .min-ld-block {
        display: block;
    }
    .min-ld-inline-block {
        display: inline-block;
    }
    .min-ld-inline {
        display: inline;
    }
}
@include respond-to(big) {
    .min-xl-block {
        display: block;
    }
    .min-xl-inline-block {
        display: inline-block;
    }
    .min-xl-inline {
        display: inline;
    }
}

/* widoczne tylko sm i mniejsze */
.hidden-md {

    @include respond-to(medium) {
        display: none;
    }
}

.pull-left,
.pull-right {
    float: none;
}
@include respond-to(medium) {
    .pull-right {
        float: right;
    }
    .pull-left{
        float: left;
    }
}

.vertical-center {
    min-height: 100%;
    display: flex;
    align-items: center;
}

.form-control-plaintext {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-size: 1rem;
}
.display-none {
    display: none;
}
.display-inline {
    display: inline;
}
.display-inline-block {
    display: inline-block;
}
.display-block {
    display: block;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
    z-index: 10;
}
.absolute-right {
    position: absolute;
    right: 1px;
    z-index: 10;
}
.absolute-right-2 {
    position: absolute;
    right: 32px;
    /*top: -3px;*/
    z-index: 10;
}
.absolute-right-3 {
    position: absolute;
    right: 63px;
    /*top: -3px;*/
    z-index: 10;
}
.absolute-right-4 {
    position: absolute;
    right: 94px;
    /*top: -3px;*/
    z-index: 10;
}
.absolute-left {
    position: absolute;
    left: 1px;
    /*top: -3px;*/
    z-index: 10;
}
.absolute-left-2 {
    position: absolute;
    left: 32px;
    /*top: -3px;*/
    z-index: 10;
}
.absolute-left-3 {
    position: absolute;
    left: 63px;
    /*top: -3px;*/
    z-index: 10;
}
.absolute-left-4 {
    position: absolute;
    left: 94px;
    /*top: -3px;*/
    z-index: 10;
}
.heart-cart {
    position: absolute;
	font-size: 24px;
	cursor: progress;
    right: 25px;
    top: 20px;
    z-index: 10;
	
	&.in-cart {
		color: color(f-red);
	}
	
	&.no-cart {
		color: color(f-white);
		text-shadow: 0 0 2px color(dark-shadow);
		
		&:hover {
			text-shadow: 0 0 3px color(dark-hover)
		}
	}

}

.info-admin {
    font-size: 10px;
    line-height: 1.1;
    color: black;
    background-color: white;
    border: 1px solid silver;
    border-radius: 3px;
    padding: 2px 5px;
    z-index: 10;
}

form {

    ::placeholder {
        color: tint(base, 50);
    }

    .required::placeholder {
        color: tint(f-red, 60);
    }
}

.table {

    tbody {
        width: 100%;

        tr {
            width: 100%;
        }
    }
}

.btn-success {
    background-color: color(f-green);
    border-color: color(f-green);
    text-shadow: 0 0 1px tint(f-green, 80);

    &:hover {
        background-color: shade(f-green, 20);
        border-color: shade(f-green, 5);
    }
}

.absolute{
    &.z-20 { position: absolute; z-index:20; }
    &.z-19 { position: absolute; z-index:19; }
    &.z-18 { position: absolute; z-index:18; }
    &.z-17 { position: absolute; z-index:17; }
    &.z-16 { position: absolute; z-index:16; }
    &.z-15 { position: absolute; z-index:15; }
    &.z-14 { position: absolute; z-index:14; }
    &.z-13 { position: absolute; z-index:13; }
    &.z-12 { position: absolute; z-index:12; }
    &.z-11 { position: absolute; z-index:11; }
    &.z-10 { position: absolute; z-index:10; }
    &.z-9  { position: absolute; z-index: 9; }
    &.z-8  { position: absolute; z-index: 8; }
    &.z-7  { position: absolute; z-index: 7; }
    &.z-6  { position: absolute; z-index: 6; }
    &.z-5  { position: absolute; z-index: 5; }
    &.z-4  { position: absolute; z-index: 4; }
    &.z-3  { position: absolute; z-index: 3; }
    &.z-2  { position: absolute; z-index: 2; }
    &.z-1  { position: absolute; z-index: 1; }
}

.z-20 { position: relative; z-index:20; }
.z-19 { position: relative; z-index:19; }
.z-18 { position: relative; z-index:18; }
.z-17 { position: relative; z-index:17; }
.z-16 { position: relative; z-index:16; }
.z-15 { position: relative; z-index:15; }
.z-14 { position: relative; z-index:14; }
.z-13 { position: relative; z-index:13; }
.z-12 { position: relative; z-index:12; }
.z-11 { position: relative; z-index:11; }
.z-10 { position: relative; z-index:10; }
.z-9  { position: relative; z-index: 9; }
.z-8  { position: relative; z-index: 8; }
.z-7  { position: relative; z-index: 7; }
.z-6  { position: relative; z-index: 6; }
.z-5  { position: relative; z-index: 5; }
.z-4  { position: relative; z-index: 4; }
.z-3  { position: relative; z-index: 3; }
.z-2  { position: relative; z-index: 2; }
.z-1  { position: relative; z-index: 1; }

.tabs { 
    font-size: 0;
    line-height: 0;

    .nav-tabs {
        list-style: none;
        margin: 0;
        padding: 6px 0 0 0;
        position: relative;
        z-index: 2; 
        font-size: 0;
        line-height: 0;

        .nav-item {
            display: inline-block;
            border: 1px solid color(modal-border);
            border-radius: 8px;
            padding: 0;
            font-size: 0;
            line-height: 0;
            background-color: color(modal-bg-inactive);
            box-shadow: 3px -2px 2px color(modal-shadow);
            text-align: center;
            margin: 0 0 0 -10px;

            /*@include respond-to(medium) {*/
            border-radius: 12px 12px 0 0;
            /*}*/

            &:first-of-type {
                margin-left: 0;
            }

            &.active {
                position: relative;
                z-index: 100;
                background-color: color(modal-bg);
                border-bottom-color: color(modal-border); 

                @include respond-to(medium) {
                    border-bottom-color: color(modal-bg);
                    margin: 0 0 -1px -10px;

                    &:first-of-type {
                        margin-left: 0;
                    }
                }
            }

            &:hover {
                background-color: color(modal-bg);
            }

            a {
                display: inline-block;
                margin: 0;
                padding: 0 15px;
                color: shade(f-blue,20);
                font-size: 14px;
                line-height: 30px;
                background-color: transparent;
                border: none;

                &.active {
                    color: shade(f-green,15);
                    background-color: transparent;
                    padding: 0 15px 1px;
                    line-height: 30px;
                    border: none;
                }
            }
        }
    }

    .tab-content {
        position: relative;
        z-index: 1;
        border: 1px solid color(modal-border);
        border-radius: 0 5px 5px 5px;
        background-color: color(modal-bg);
        padding: 10px 15px;
        top: -1px;
        margin-top: -1px;
        font-size: 14px;
        line-height: 1.25;
        box-shadow: 5px -2px 5px color(modal-shadow);

        @include respond-to(medium) {
            padding:15px;
        }
        @include respond-to(large) {
            padding: 20px 40px;
        }

        .tab-pane.container {
            margin: 0;
            padding: 0 5px;
            max-width: 100%;

            @include respond-to(medium) {
                max-width: 100%;
                padding: 0 5px;
            }
            @include respond-to(large) {
                padding: 0 auto;
            }
        }


        small, 
        .smaller {
            font-size: 13px;
        }

        .form-control-plaintext,
        .form-control,
        button {
            font-size: 14px;
        }

        .form-check-input {
            margin-top: 0;
        }
    }
}

.pagination{
    
    &.pagination-sm {
        .page-item {
            .page-link {
                line-height: 1.15;
            }
        }
    }
    
    .page-item {
        background-color: white;
        cursor: pointer;
        
        .page-link {
            background-color: transparent;
            color: color(active);
            font-size: 12px;
            font-weight: bold;
            
            &:hover{
                color: black;
            }
        }
        
        &.disabled{
            background-color: white;
            cursor: default;
            
            .page-link {
                color: color(inactive);
            }
        }
        
        &.active{
            background-color: color(active);
            cursor: default;
            
            .page-link {
                border-color: color(active);
                color: white;
            }
        }
    }
}

.text-small{font-size: smaller;}

#afterheader {
	margin: -68px 0 68px 0; padding:0; font-size:0; line-height: 0;
	
	@include respond-to(small) {
	
		margin: 0;
	}
}

.one-line {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.no-wrap {
	white-space: nowrap;
}
@charset 'utf-8';

footer {
    z-index: 1;
    margin: 20px 0 0;
    padding: 0 0 61px 0;
    
    .active {
        
        a {
            color: color(active);
        }
    }
    
    a {
        color: color(footer-link);
        
        &:hover,
        {
            color: tint(footer-link, 30);
            text-decoration: none;
        }
    }
    
    .top-foot {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 61px;
        /*background-color: shade(f-blue,50);*/
        background-color: shade(f-green,10);
        color: color(f-white);
        font-weight: bold;
        font-size: 3vw;
        line-height: 1.1;
        text-shadow: 0 0 1px shade(f-green, 50);
        padding-top: 8px;
        padding-bottom: 8px;
        z-index: 20;
        
        @include respond-to(very-very-small) {
            font-size: 9px;
            line-height: 1.15;
        }

        @include respond-to(very-small) {
            font-size: 10px;
            line-height: 1.15;
        }

        @include respond-to(medium-small) {
            font-size: 11px;
            line-height: 1.2;
        }
        
        @include respond-to(small) {
            font-size: 12px;
            line-height: 1.3;
        }
        
        @include respond-to(medium) {
            font-size: 12px;
            line-height: 1.6;
        }
        
        @include respond-to(large) {
            font-size: 14px;
            line-height: 1.5;
        }

        @include respond-to(big) {
            font-size: 14px;
            line-height: 1.5;
        }

        p {
            padding: 0 auto;
            margin: 0 auto 0.5rem;
            
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    
    .info-foot {
        background-color: shade(f-blue, 85);
        color: color(footer);
        padding-top: 15px;
        
        @include respond-to(large) {
            padding-left: 30px;
            padding-right: 30px;
        }
        
        .row {
            
            >div {              

                z-index: 0;

                h5 {
                    margin: 5px 0;
                    padding: 5px 0;
                    font-weight: bolder;
                    color: shade(f-blue,30);
                }

                .list-group-flush {
                    padding-left: 0;
					margin: 0 0 1rem 0;
					display: block;
					width: 100%;
					font-size: 0;

                    .list-group-item {
						display: block;
						width: 100%;
						margin: 0 0 -1px 0;
                        background-color: transparent;
                        border-top: 0;
                        border-bottom: 0;
                        color: shade(footer, 35);
                        font-size: 12px;
                        padding: 0;
                        white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
                        
                        @include respond-to(small) {
                            font-size: 12px;
                        }

                        
                        @include respond-to(medium) {
                            padding: 0 0;
                            font-size: 13px;
                        }

                        @include respond-to(large) {
                            padding: 0.15rem 0;
                            font-size: 12px;
                        }
                        
                        @include respond-to(big) {
                            padding: 0.3rem 0;
                            font-size: 14px;
                        }
                        
                        strong {
                            font-size: smaller;
                        }
                    }
                }
            }
        }        
    }
    
    .foot {
        border-top: 1px solid shade(f-blue,60);
        background-color: shade(f-blue, 95);
        color: shade(footer-bg, 20);
        overflow: none;
        margin: 0;
        padding: 5px 15px;
        
        &::after,
        &::before {
            content: "";
            display: table;
            font-size: 0;
            line-height: 0;
            clear: both;
            width: 100%;
        }
        @media all and (max-width:576px) {
            .float-left, .float-right {
                float:inherit !important;
            }
        }

        div {
            font-size: 10px; 
            line-height: 20px; 
            text-align: center; 
            white-space: nowrap;
            overflow: auto;

            @include respond-to(small) {
                font-size: 12px;
            }

            &:first-of-type {
                padding-right: 15px;
            }

            &:last-of-type {
                padding-left: 15px;
            }

            @include respond-to(large) {
                padding: 0 15px;
            }
        }   
    }
}

footer.org-footer {

    .info-foot {
        
        .row {
            
            >div {              

                .list-group-flush {
                    padding-left: 0;
					margin: 0 0 1rem 0;
					display: block;
					width: 100%;
					font-size: 0;

                    .list-group-item {
						display: block;
						width: 100%;
						margin: 0 0 -1px 0;
                        background-color: transparent;
                        border-top: 0;
                        border-bottom: 0;
                        color: shade(footer, 35);
                        font-size: 12px;
                        padding: 0;
                        white-space: normal;
						overflow: hidden;
						text-overflow: clip;
                        
                    }
                }
            }
        }        
    }
    
}